var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('img', {
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "src": _vm.appLogoImage,
      "alt": ""
    }
  })])], 1), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Lupa Password? 🔒 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Silahkan masukan email akun anda dan kami akan kirim link untuk mereset akun anda. ")]), _c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', {
    staticClass: "auth-forgot-password-form mt-2",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "forgot-password-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "forgot-password-email",
            "state": errors.length > 0 ? false : null,
            "name": "forgot-password-email",
            "placeholder": "namaku@armezza.com"
          },
          model: {
            value: _vm.userEmail,
            callback: function ($$v) {
              _vm.userEmail = $$v;
            },
            expression: "userEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit"
    }
  }, [_vm._v(" Kirim link reset ")])], 1)], 1), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        path: 'login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Kembali ke login ")], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }